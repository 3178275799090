<template>
  <div class="Comment">
    <van-cell v-for="(item,index) in Dlist" :key="index" :title="item.reccuname" :value="item.reccatime" :label="item.recccontent+item.eacrecontent">

      <template #title>
        <span class="custom-title">{{item.recccontent}}</span>
      </template>

      <template #label v-if="item.eacreischeck==1 && item.eacrecontent!=''">
        <span class="custom-title">回复：{{item.eacrecontent}}</span>
      </template>

    </van-cell>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant';
import { com_comment_add_do, com_info_list_comment } from '@/api/J_comment'
export default {
  name: 'Comment',
  setup(props) {
    let $route = useRouter();
    let parameter = {
      guid: $route.currentRoute.value.params.id,
    };
    let Dlist = ref([]);
    //方法
    //评论列表
    const Clist = () => {
      com_info_list_comment(parameter).then((res) => {
        Dlist.value = res.data.mydata.data
        console.log('lb', res.data.mydata.data)
      }).catch((err) => {
        console.log(err)
      });
    };
    onMounted(() => {
      Clist()
    });
    return {
      Dlist,
      Clist
    }
  }
}
</script>
<style scoped lang="less">
//头部样式
/deep/ .van-icon-arrow-left {
  color: #323233;
}
/deep/ .van-nav-bar__text {
  color: #323233;
  //   color: #fd8412;
}
// 输入框样式调整
/deep/ .van-cell-group--inset {
  margin-top: 2rem;
}
</style>
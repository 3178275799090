import axios from '../http' // 导入http中创建的axios实例
import Qs from 'qs' // 根据需求是否导入qs模块
import{ resquest } from './base'
// let resquest = ""
// ++++++++++评论相关接口++++++++++

//评论列表
export function com_info_list_comment(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_consumer/com_info_list_comment`, data2);
}
//消费者评论
export function com_comment_add_do(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_consumer/com_comment_add_do`, data2);
}